// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "@fortawesome/fontawesome-free/css/all";
// import '../scripts'
// import jQuery from "jquery";
// // include jQuery in global and window scope (so you can access it globally)
// // in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
// global.$ = global.jQuery = jQuery;
// window.$ = window.jQuery = jQuery;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "./custom_bootstrap.scss";
import "../scss/main.scss";

const csrfMeta = document.querySelector('meta[name="csrf-token"]');
const csrfToken = csrfMeta ? csrfMeta.getAttribute('content') : '';
let editorWidth;
let editorHeight;
document.addEventListener('DOMContentLoaded', () => {
document.querySelectorAll('.ck-editor5').forEach(function (val) {
    ClassicEditor
        .create(val, 
            {
                simpleUpload: {
                    uploadUrl: '/uploads',
                    headers: {
                        'X-CSRF-TOKEN': csrfToken,
                    }
                },
                image: {
                    toolbar: [ 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|','toggleImageCaption', 'imageTextAlternative' ]
                },
                mediaEmbed: {
                    previewsInData: true
                },
                fontSize: {
                    options: [9, 11, 13, "default", 17, 19, 21],
                },
                fontColor: 
                    {
                        columns: 5,
                        documentColors: 24
                    }
                
            }
            
        ).then( editor => {
            editor.model.document.on('change', (eventInfo, name, value, oldValue) => {
                const newWidth = editor.ui.view.element.offsetWidth;
                const newHeight = editor.ui.view.element.offsetHeight;
        
                if(editorWidth !== newWidth || editorHeight !== newHeight) {
                  console.log('Editor size changed. New size:', newWidth, newHeight);
                  editorWidth = newWidth;
                  editorHeight = newHeight;
                }
            });
        } )
        .catch(error => {
            console.log(error);
        });
});
})
// document.addEventListener('DOMContentLoaded', () => {
   
//     console.log('dd');
//   })